import React, { useState, useEffect }  from "react";
import { BoxDiv, Button, Container, HeaderDiv, HeaderTitle, InfoBoxDiv, InfoContent, InfoDiv, InfoTitle, Logo, Table, Td, Th, Tr } from "./styles";
import PaxBackground from '../../assets/ReportValidator/PaxBackground.svg';
import TransireLogo from '../../assets/ReportValidator/TransireLogo.png';
import jwt from 'jsonwebtoken';

export const ReportValidator = (props: any) => {

    const JWT_KEY = process.env.REACT_APP_JWT_KEY || '';
    const [token, setToken] = useState<string>(props.match.params.token);
    const [reporTdata, setreporTdata] = useState<any>({});
    const [isValidToken, setisValidToken] = useState(false);

    useEffect(() => {
        getTokenData();
    }, []);

    const getTokenData = async () => {
        jwt.verify(token, JWT_KEY, (err, decoded) => {
            if(!err) {
                setreporTdata(decoded);
                setisValidToken(true);
            }
        });
    }

    return (
        <Container background={PaxBackground}>
            <HeaderDiv>
                <Logo src={TransireLogo} />
                <HeaderTitle>{isValidToken ? 'Valide seu documento' : 'Laudo inválido'}</HeaderTitle>
            </HeaderDiv>

            {isValidToken && (
                <BoxDiv>
                    <InfoDiv>
                        <InfoBoxDiv>
                            <div>
                                <InfoTitle>Empresa</InfoTitle>
                                <InfoContent>{reporTdata.cliente}</InfoContent>
                            </div>
                        </InfoBoxDiv>                
                        <InfoBoxDiv>
                            <div>
                                <InfoTitle>Part Number</InfoTitle>
                                <InfoContent>{reporTdata.partNumber}</InfoContent>
                            </div>
                            <div>
                                <InfoTitle>Serial</InfoTitle>
                                <InfoContent>{reporTdata.serial}</InfoContent>
                            </div>
                        </InfoBoxDiv>
                        <InfoBoxDiv>
                            
                            <div>
                                <InfoTitle>Nome do arquivo</InfoTitle>
                                <InfoContent>{`${reporTdata.serial}.pdf`}</InfoContent>
                            </div>
                            <div>
                                <InfoTitle>Assinado em</InfoTitle>
                                <InfoContent>{reporTdata.dataEntrada}</InfoContent>
                            </div>
                        </InfoBoxDiv>
                        <InfoBoxDiv>
                            <div>
                                <InfoTitle>Nota Fiscal</InfoTitle>
                                <InfoContent>{reporTdata.notaFiscal}</InfoContent>
                            </div>
                            <div>
                                <InfoTitle>Operador Logístico</InfoTitle>
                                <InfoContent>{reporTdata.operadorLogistico}</InfoContent>
                            </div>
                        </InfoBoxDiv>
                        {reporTdata.ativo && (
                            <InfoBoxDiv>
                                <div>
                                    <InfoTitle>Ativo</InfoTitle>
                                    <InfoContent>{reporTdata.ativo}</InfoContent>
                                </div>
                            </InfoBoxDiv>
                        )}
                            <Table>
                                <tbody>
                                    <Tr>
                                        <Th>Status Final</Th>
                                        <Td>{reporTdata.statusFinal}</Td>
                                    </Tr>
                                    <Tr>
                                        <Th>Classificação</Th>
                                        <Td>{reporTdata.classificacao}</Td>
                                    </Tr>
                                    <Tr>
                                        <Th>Falha Constatada</Th>
                                        <Td>{reporTdata.falhaConstatada}</Td>
                                    </Tr>
                                    <Tr>
                                        <Th>Motivo</Th>
                                        <Td>{reporTdata.motivo}</Td>
                                    </Tr>
                                    <Tr>
                                        <Th>Observações</Th>
                                        <Td></Td>
                                    </Tr>
                                </tbody>
                            </Table>
                    </InfoDiv>
                </BoxDiv>
            )}
        </Container>
    );
}