import styled from 'styled-components';

interface Container {
    background: string;
}

interface Image {
    src: string;
}

export const Container = styled.div<Container>`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("${props => props.background}");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    
    @media(max-width: 661px) {
        background-size: initial;
        background-attachment: fixed;
    }
    overflow-y: scroll;
`;

export const BoxDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 10px;
`;

export const HeaderDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    margin-top: 16px;
`;

export const HeaderTitle = styled.p`
    font-size: 20px;
    font-weight: bold;
    font-family: 'Source Sans Pro', Helvetica, serif;
    color: #5D5D64;
    margin-top: 18px;
    
`;
export const Logo = styled.img<Image>`
    width: 85px;
    height: 35px;
    src: ${props => props.src};
`;

export const InfoDiv = styled.div`
    width: 500px;
    margin-top: 25px;

    @media(max-width: 524px) {
        width: 100%;
    }

`;

export const InfoTitle = styled.p`
    font-size: 14px;
    font-family: 'Source Sans Pro', Helvetica, serif;
    color: #5D5D64;
    margin-bottom: 5px;
`;
export const InfoContent = styled.p`
    font-size: 16px;
    font-weight: bold;
    font-family: 'Source Sans Pro', Helvetica, serif;
    color: #272731;
    margin-bottom: 23px;
`;

export const InfoBoxDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

export const Table = styled.table`
    width: 100%;
    border: none;
    border-spacing: 0 0.0em;
    word-wrap: break-word;
    table-layout: fixed;
    border: 0.5px solid #C9C9CC;
`;

export const Th = styled.th`
    text-align: center;
    font-size: 14px;
    font-family: 'Source Sans Pro', Helvetica, serif;
    color: #5D5D64;
    padding-left: 5px;
    background: #C9C9CC;
`;

export const Tr = styled.tr`
    height: 40px;
    margin-bottom: 10px;
    border-radius: 5px;
`;

export const Td = styled.td`
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    font-family: 'Source Sans Pro', Helvetica, serif;
    color: #5D5D64;
    margin-bottom: 23px;
    padding-left: 5px;
    border-bottom: 0.5px solid #C9C9CC;
    
`;


export const InputDiv = styled.div`
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 20px;

    @media(max-width: 524px) {
        width: 100%;
    }
`;

export const InputTitle = styled.p`
    font-size: 14px;
    font-family: 'Source Sans Pro', Helvetica, serif;
    color: #5D5D64;
    margin-bottom: 5px;
`;

export const Input = styled.input`
    width: 500px;
    height: 38px;
    border-radius: 5px;
    border: 1px solid #E0E0E0;
    padding-left: 10px;
    padding-right: 10px;

    @media(max-width: 524px) {
        width: 100%;
    }
`;

export const Button = styled.button`
    width: 171px;
    height: 42px;
    background: #3467D7;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    font-family: 'Source Sans Pro', Helvetica, serif;
    color: white;
    cursor: pointer;

    :hover {
        background: #48A5F0;
    }
`;


