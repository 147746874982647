import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ReportValidator } from '../pages/ReportValidator';

export const Routes = () => {
    return(
        <BrowserRouter>
            <Switch>
                <Route path="/:token" component={ReportValidator} />
            </Switch>
        </BrowserRouter>
    );
}